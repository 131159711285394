import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { interiorTheme } from "common/theme/interior";
import Layout from "../containers/Home/Layout";
import Titlebar from "common/components/Titlebar";
import "common/assets/css/main-page.css";

import Seo from "components/seo";


const Contact = () => {
  const seoData = {
    title: "Contact Radiance Hair Studio | Book Appointments & Inquiries",
    description: "Get in touch with Radiance Hair Studio for appointments, inquiries, and expert consultations. Contact us today to book your next hair care session.",
    keywords: ["Contact Radiance Hair Studio"],
  };


  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} />
        <Layout>
          <Titlebar title="Contact Us" />
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default Contact;